import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector"
      ].some(function(fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    //se obtiene la url y sus parámetros
    let match = this.props.location.pathname;
    //se divide la url y sus parámetros
    let match_split = match.split("/");
    //si desde una organización se hace clic en marcas se muestra el listado de sus marcas
    let stylesorganizacion = {
      display:
        match_split[3] === "marca" ||
        match_split[3] === "experiencias" ||
        match_split[3] === "momentos" ||
        match_split[3] === "puntosdecontacto" ||
        match_split[3] === "indicadores" ||
        match_split[3] === "variables" ||
        match_split[3] === "acciones" ||
        match_split[3] === "canales" ||
        match_split[3] === "tramos" ||
        match_split[3] === "atributos" ||
        match_split[3] === "elementos" ||
        match_split[3] === "proyectos" ||
        match_split[3] === "instrumentos" ||
        match_split[3] === "procesos" ||
        match_split[3] === "preguntas" ||
        match_split[3] === "puntos" ||
        match_split[3] === "periodos"
          ? "block"
          : "none"
    };
    //si desde una marca se hace click en opciones por default se muestran sus experiencias
    //además se muestra en el menú las experiencias, atributos, puntos, proyectos y periodos de la marca
    let stylesmarca = {
      display:
        match_split[3] === "experiencias" ||
        match_split[3] === "momentos" ||
        match_split[3] === "puntosdecontacto" ||
        match_split[3] === "indicadores" ||
        match_split[3] === "variables" ||
        match_split[3] === "acciones" ||
        match_split[3] === "canales" ||
        match_split[3] === "tramos" ||
        match_split[3] === "atributos" ||
        match_split[3] === "elementos" ||
        match_split[3] === "proyectos" ||
        match_split[3] === "instrumentos" ||
        match_split[3] === "procesos" ||
        match_split[3] === "preguntas" ||
        match_split[3] === "puntos" ||
        match_split[3] === "periodos"
          ? "block"
          : "none"
    };
    //si desde una experiencia se hace click en opciones se muestran sus momentos por default
    //además se muestran sus puntos de contacto, indicadores, variables, acciones, tramos y canales
    let stylesexperiencia = {
      display:
        match_split[3] === "momentos" ||
        match_split[3] === "puntosdecontacto" ||
        match_split[3] === "indicadores" ||
        match_split[3] === "variables" ||
        match_split[3] === "acciones" ||
        match_split[3] === "canales" ||
        match_split[3] === "tramos"
          ? "block"
          : "none"
    };
    //si desde un proyecto se hace click en opciones se muestran sus instrumentos
    let stylesproyecto = {
      display:
        match_split[3] === "proyectos" ||
        match_split[3] === "instrumentos" ||
        match_split[3] === "procesos" ||
        match_split[3] === "preguntas"
          ? "block"
          : "none"
    };
    //si desde un instrumento se hace click en opciones se muestran sus preguntas
    let stylesinstrumentos = {
      display:
        match_split[3] === "instrumentos" || match_split[3] === "preguntas"
          ? "block"
          : "none"
    };
    //si desde un atributo se hace click en opciones se muestran sus elementos
    let stylesatributo = {
      display:
        match_split[3] === "atributos" || match_split[3] === "elementos"
          ? "block"
          : "none"
    };
    //se construyen los parámetros en cada url
    let params_url_marcas = "";
    let params_url_marcas_settings = "";
    let params_url_experiencias_settings = "";
    let params_url_proyectos_settings = "";
    let params_url_instrumentos_settings = "";
    let params_url_atributos_settings = "";
    let open_active_organizaciones = "";
    let open_active_marcas = "";
    let open_active_experiencias = "";
    let open_active_proyectos = "";
    let open_active_instrumentos = "";
    let open_active_atributos = "";
    let open_active_usuarios = "";
    let open_active_apikeys = "";
    let open_active_widgets = "";
    //primer nivel (no ha seleccionado un usuario)
    if (
      match_split[3] === "usuario" ||
      match_split[3] === "roles" ||
      match_split[3] === "rutas" ||
      match_split[3] === "ingresos"
    ) {
      open_active_usuarios = "open";
    }
    //segundo nivel (ingresa al modulo usuarios)
    if (match_split[3] === "apikey") {
      open_active_apikeys = "open active";
    }

    if (match_split[2] === "widgets" || match_split[3] === "listado") {
      open_active_widgets = "open active";
    }

    //primer nivel (no ha seleccionado una organización)
    if (match_split[3] === "organizacion") {
      open_active_organizaciones = "open active";
    }
    //segundo nivel (se ha seleccionado una organización)
    if (match_split[3] === "marca") {
      params_url_marcas = match_split[4];
      open_active_organizaciones = "open active";
      open_active_marcas = "open active";
    }

    //tercer nivel (se ha seleccionado una marca)
    if (
      match_split[3] === "experiencias" ||
      match_split[3] === "atributos" ||
      match_split[3] === "proyectos" ||
      match_split[3] === "puntos" ||
      match_split[3] === "periodos"
    ) {
      params_url_marcas = match_split[4];
      params_url_marcas_settings = match_split[4] + "/" + match_split[5];
      open_active_organizaciones = "open active";
      open_active_marcas = "open active";
      open_active_experiencias = "open active";
    }
    //cuarto nivel (se ha seleccionado una experiencia)
    if (
      match_split[3] === "momentos" ||
      match_split[3] === "puntosdecontacto" ||
      match_split[3] === "indicadores" ||
      match_split[3] === "variables" ||
      match_split[3] === "acciones" ||
      match_split[3] === "tramos" ||
      match_split[3] === "canales"
    ) {
      params_url_marcas = match_split[4];
      params_url_marcas_settings = match_split[4] + "/" + match_split[5];
      params_url_experiencias_settings =
        match_split[4] + "/" + match_split[5] + "/" + match_split[6];
      open_active_organizaciones = "open active";
      open_active_marcas = "open active";
      open_active_experiencias = "open active";
    }
    //tercer nivel (se ha seleccionado un proyecto)
    if (match_split[3] === "instrumentos" || match_split[3] === "procesos") {
      params_url_marcas = match_split[4];
      params_url_marcas_settings = match_split[4] + "/" + match_split[5];
      params_url_proyectos_settings =
        match_split[4] + "/" + match_split[5] + "/" + match_split[6];
      open_active_organizaciones = "open active";
      open_active_marcas = "open active";
      open_active_proyectos = "open active";
    }
    //cuarto nivel (se ha seleccionado un proyecto)
    if (match_split[3] === "preguntas") {
      params_url_marcas = match_split[4];
      params_url_marcas_settings = match_split[4] + "/" + match_split[5];
      params_url_proyectos_settings =
        match_split[4] + "/" + match_split[5] + "/" + match_split[6];
      params_url_instrumentos_settings =
        match_split[4] +
        "/" +
        match_split[5] +
        "/" +
        match_split[6] +
        "/" +
        match_split[7];
      open_active_organizaciones = "open active";
      open_active_marcas = "open active";
      open_active_proyectos = "open active";
      open_active_instrumentos = "open active";
    }
    //tercer nivel (se ha seleccionado un atributo)
    if (match_split[3] === "elementos") {
      params_url_marcas = match_split[4];
      params_url_marcas_settings = match_split[4] + "/" + match_split[5];
      params_url_atributos_settings =
        match_split[4] + "/" + match_split[5] + "/" + match_split[6];
      open_active_organizaciones = "open active";
      open_active_marcas = "open active";
      open_active_atributos = "open active";
    }

    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className={"menu " + open_active_usuarios}>
            <NavLink className="prepend-icon" to="/app/usuarios/usuario">
              <i className="material-icons">people_alt</i>
              <span className="nav-text">Usuarios</span>
            </NavLink>
            <ul className="sub-menu">
              <li className="">
                <NavLink className="prepend-icon" to="/app/usuarios/roles">
                  <i className="material-icons">person_outline</i>
                  <span className="nav-text">Roles</span>
                </NavLink>
              </li>
              <li className="">
                <NavLink className="prepend-icon" to="/app/usuarios/rutas">
                  <i className="material-icons">link</i>
                  <span className="nav-text">Rutas</span>
                </NavLink>
              </li>
              <li className="">
                <NavLink className="prepend-icon" to="/app/usuarios/ingresos">
                  <i className="material-icons">swap_vertical_circle</i>
                  <span className="nav-text">Ingresos</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={"menu " + open_active_apikeys}>
            <NavLink className="prepend-icon" to="/app/apikeys/apikey">
              <i className="material-icons">vpn_key</i>
              <span className="nav-text">Api Keys</span>
            </NavLink>
          </li>
          <li className={"menu " + open_active_widgets}>
            <NavLink className="prepend-icon" to="/app/widgets/listado">
              <i className="material-icons">widgets</i>
              <span className="nav-text">Widgets</span>
            </NavLink>
          </li>
          <li className={"menu " + open_active_organizaciones}>
            <NavLink
              className="prepend-icon"
              to="/app/organizaciones/organizacion"
            >
              <i className="material-icons">business</i>
              <span className="nav-text">Organizaciones</span>
            </NavLink>
            <ul className="sub-menu">
              <li
                className={"menu " + open_active_marcas}
                style={stylesorganizacion}
              >
                <NavLink
                  className="prepend-icon"
                  to={"/app/marcas/marca/" + params_url_marcas}
                >
                  <i className="material-icons">branding_watermark</i>
                  <span className="nav-text">Marcas</span>
                </NavLink>
                <ul className="sub-menu">
                  <li
                    className={"menu " + open_active_experiencias}
                    style={stylesmarca}
                  >
                    <NavLink
                      className="prepend-icon"
                      to={
                        "/app/marcas/experiencias/" + params_url_marcas_settings
                      }
                    >
                      <i className="material-icons">insert_emoticon</i>
                      <span className="nav-text">Experiencias</span>
                    </NavLink>
                    <ul className="sub-menu">
                      <li style={stylesexperiencia}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/momentos/" +
                            params_url_experiencias_settings
                          }
                        >
                          <i className="material-icons">av_timer</i>
                          <span className="nav-text">Momentos</span>
                        </NavLink>
                      </li>
                      <li style={stylesexperiencia}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/puntosdecontacto/" +
                            params_url_experiencias_settings
                          }
                        >
                          <i className="material-icons">bookmarks</i>
                          <span className="nav-text">P. contacto</span>
                        </NavLink>
                      </li>
                      <li style={stylesexperiencia}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/indicadores/" +
                            params_url_experiencias_settings
                          }
                        >
                          <i className="material-icons">drag_indicator</i>
                          <span className="nav-text">Indicadores</span>
                        </NavLink>
                      </li>
                      <li style={stylesexperiencia}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/variables/" +
                            params_url_experiencias_settings
                          }
                        >
                          <i className="material-icons">list</i>
                          <span className="nav-text">Variables</span>
                        </NavLink>
                      </li>
                      <li style={stylesexperiencia}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/acciones/" +
                            params_url_experiencias_settings
                          }
                        >
                          <i className="material-icons">call_to_action</i>
                          <span className="nav-text">Acciones</span>
                        </NavLink>
                      </li>
                      <li style={stylesexperiencia}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/tramos/" +
                            params_url_experiencias_settings
                          }
                        >
                          <i className="material-icons">trending_flat</i>
                          <span className="nav-text">Tramos</span>
                        </NavLink>
                      </li>
                      <li style={stylesexperiencia}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/canales/" +
                            params_url_experiencias_settings
                          }
                        >
                          <i className="material-icons">library_books</i>
                          <span className="nav-text">Canales</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={"menu " + open_active_atributos}
                    style={stylesmarca}
                  >
                    <NavLink
                      className="prepend-icon"
                      to={"/app/marcas/atributos/" + params_url_marcas_settings}
                    >
                      <i className="material-icons">note_add</i>
                      <span className="nav-text">Atributos</span>
                    </NavLink>
                    <ul className="sub-menu">
                      <li style={stylesatributo}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/elementos/" +
                            params_url_atributos_settings
                          }
                        >
                          <i className="material-icons">list_alt</i>
                          <span className="nav-text">Elementos</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={"menu " + open_active_proyectos}
                    style={stylesmarca}
                  >
                    <NavLink
                      className="prepend-icon"
                      to={"/app/marcas/proyectos/" + params_url_marcas_settings}
                    >
                      <i className="material-icons">business_center</i>
                      <span className="nav-text">Proyectos</span>
                    </NavLink>
                    <ul className="sub-menu">
                      <li
                        className={"menu " + open_active_instrumentos}
                        style={stylesproyecto}
                      >
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/instrumentos/" +
                            params_url_proyectos_settings
                          }
                        >
                          <i className="material-icons">build</i>
                          <span className="nav-text">Instrumentos</span>
                        </NavLink>
                        <ul className="sub-menu">
                          <li style={stylesinstrumentos}>
                            <NavLink
                              className="prepend-icon"
                              to={
                                "/app/marcas/preguntas/" +
                                params_url_instrumentos_settings
                              }
                            >
                              <i className="material-icons">contact_support</i>
                              <span className="nav-text">Preguntas</span>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li style={stylesproyecto}>
                        <NavLink
                          className="prepend-icon"
                          to={
                            "/app/marcas/procesos/" +
                            params_url_proyectos_settings
                          }
                        >
                          <i className="material-icons">extension</i>
                          <span className="nav-text">Procesos</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li style={stylesmarca}>
                    <NavLink
                      className="prepend-icon"
                      to={"/app/marcas/puntos/" + params_url_marcas_settings}
                    >
                      <i className="material-icons">my_location</i>
                      <span className="nav-text">Puntos</span>
                    </NavLink>
                  </li>
                  <li style={stylesmarca}>
                    <NavLink
                      className="prepend-icon"
                      to={"/app/marcas/periodos/" + params_url_marcas_settings}
                    >
                      <i className="material-icons">calendar_today</i>
                      <span className="nav-text">Periodos</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
