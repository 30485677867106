import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Usuarios = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/usuarios`}/>
      <Route path={`${match.url}/usuario`} component={asyncComponent(() => import('./routes/Usuario'))}/>
      <Route path={`${match.url}/roles`} component={asyncComponent(() => import('./routes/Roles'))}/>
      <Route path={`${match.url}/rutas`} component={asyncComponent(() => import('./routes/Rutas'))}/>
      <Route path={`${match.url}/ingresos`} component={asyncComponent(() => import('./routes/Ingresos'))}/>
      <Route path={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Usuarios;
